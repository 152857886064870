.submit-button {
  background-color: #3ddc91;
  border: 4px solid #3ddc91;
  border-radius: 4px;
  color: #132322;
  cursor: pointer;
  display: inline-block;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  /* letter-spacing: 2px; */
  font-family: DM Sans, Arial, Helvetica, sans-serif;
  line-height: initial;
  margin-bottom: 15px;
  padding: 10px 30px;
  text-decoration: none;
  width: 100%;
}
