.checkout_complete_container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 30px 15px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pony_express {
  width: 100%;
  height: 100%;
  max-width: 72px;
  max-height: 72px;
  margin: 30px auto;
}

.complete-header {
  font-family: "DM Mono", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.complete-text {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 70px;
}
.complete-header,
.complete-text {
  max-width: 450px;
}

.checkout_complete_container .btn {
  border: none;
  background-color: #3ddc91;
  border-radius: 4px;
  color: #132322;
}

@media only screen and (min-width: 900px) {
  .checkout_complete_container {
    padding-bottom: 100px;
  }
}
