.product_label {
  position: absolute;
  left: 110px;
  top: 25px;
  font-size: 32px;
  font-weight: 100;
  color: #fff;
}

.inventory_container {
  background-color: #fff;
  overflow: hidden;
  padding-top: 40px;
  margin: 0 auto 30px auto;
}

.inventory_list {
  max-width: 1060px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  padding: 0 15px 30px;
}
