.inventory_item {
  border: 1px solid #ededed;
  background: #fff;
  position: relative;
  margin-bottom: 12px;
  display: flex;
  box-sizing: border-box;
  border-radius: 8px;
}

.inventory_item_img {
  flex: 1;
  overflow: hidden;
}
.inventory_item_img a {
  display: block;
  height: 100%;
  width: 100%;
}
.inventory_item_img img {
  height: 100%;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.inventory_item_description {
  flex: 2;
  flex-direction: column;
  padding: 20px 34px 20px 20px;
  display: flex;
  justify-content: space-between;
}

.inventory_item_label > a {
  color: #4a4a4a;
  text-decoration: none;
}
.inventory_item_name {
  font-family: "DM Mono", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #18583a;
}
.align_right {
  text-align: right;
}
.inventory_item_desc {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  color: #132322;
}
.inventory_item .inventory_item_desc {
  width: inherit;
}
.pricebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.inventory_item_price {
  border-top: 1px solid #ededed;
  color: #132322;
  font-family: "DM Mono", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  display: inline-block;
  padding-top: 5px;
}

@media only screen and (min-width: 1060px) {
  .inventory_item {
    height: 240px;
    width: 505px;
  }
}

@media only screen and (max-width: 1060px) {
  .inventory_item {
    height: 240px;
    width: 100%;
  }
  .pricebar .btn {
    width: 160px;
  }
}

@media only screen and (max-width: 480px) {
  .inventory_item {
    height: inherit;
    flex-direction: column;
    border: none;
    margin-bottom: 40px;
  }

  .inventory_item_img {
    box-sizing: border-box;
    padding: 0 10px;
  }
  .inventory_item_img img {
    border-radius: 0;
    width: 100%;
  }

  .inventory_item_description {
    padding-bottom: 0;
  }

  .pricebar {
    align-items: flex-start;
    border-bottom: 1px solid #ededed;
    flex-direction: column;
    padding-bottom: 40px;
  }

  .inventory_item_price {
    margin: 20px 0;
    padding: 20px 10px 0 0;
  }

  .pricebar .btn {
    width: 100%;
  }
}
