.login_container {
  background-color: #eefcf6;
  min-height: 100vh;
}
.login_logo {
  font-family: DM Mono, "sans-serif";
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  padding: 30px 0;
}
.login_wrapper {
  margin: 0px auto;
}
.login_wrapper-inner {
  overflow: hidden;
}
.login_wrapper-inner,
.login_credentials_wrap-inner {
  width: 70%;
  max-width: 780px;
  padding: 15px 30px 30px;
  margin: 0 auto;
  border: 1px solid #ededed;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.login_wrapper-inner {
  background-color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.login_credentials_wrap {
  color: #ffffff;
  font-family: DM Mono, "sans-serif";
}
.form_column {
  float: none;
  width: 100%;
}

.login_credentials {
  clear: both;
  line-height: 24px;
}

.login_credentials_wrap-inner {
  background-color: #132322;
  overflow: hidden;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
@media only screen and (min-width: 900px) {
  .form_column {
    max-width: 292px;
    padding-top: 30px;
    margin: 0 auto;
  }
}
