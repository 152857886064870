.form_group {
  margin-bottom: 15px;
  position: relative;
}
.input_error {
  font-family: DM Sans, Arial, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #ededed;
  outline: none;
  padding: 10px 0;
}

.input_error.error {
  border-bottom-color: #e2231a;
}

.error_icon {
  color: #e2231a;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 12px;
}

/* Chrome/Opera/Safari */
.form_input::-webkit-input-placeholder,
    /* Firefox 19+ */
.form_input::-moz-placeholder,
    /* IE 10+ */
.form_input:-ms-input-placeholder,
.form_input::placeholder {
  color: #6d7584;
}

/* Chrome/Opera/Safari */
.form_input.error::-webkit-input-placeholder,
    /* Firefox 19+ */
.form_input.error::-moz-placeholder,
    /* IE 10+ */
.form_input.error:-ms-input-placeholder,
.form_input.error::placeholder {
  color: #e2231a !important;
}
