.checkout_summary_container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px 30px;
}

.summary_info_label {
  padding: 20px 0 0;
}

.summary_value_label {
  font-weight: 800;
  padding: 5px 0 20px 0;
}

.summary_subtotal_label {
  padding: 20px 0 10px;
}

.summary_tax_label {
  padding: 0;
}

.summary_total_label {
  padding: 10px 0;
  font-weight: 800;
  margin: 0 0 8px 0;
}

.summary_info_label {
  font-family: "DM Mono", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.summary_value_label {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.summary_info_label,
.summary_value_label,
.summary_subtotal_label,
.summary_tax_label,
.summary_total_label {
  color: #132322;
}

.cart_footer .btn_secondary.back {
  color: #132322;
  border: 1px solid #132322;
}

.cart_footer .btn_secondary.back .back-image {
  left: 12px;
}

@media only screen and (min-width: 900px) {
  .summary_info_label {
    padding: 20px 0 0;
  }

  .summary_value_label {
    padding: 5px 0 0 0;
  }

  .summary_subtotal_label {
    padding: 0;
  }
}
