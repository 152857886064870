.checkout_info_container {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 30px;
}

.checkout_info_wrapper {
  padding: 0 15px;
}

.checkout_info {
  max-width: 380px;
  margin: 0 auto;
  padding-top: 30px;
}

.checkout_buttons {
  margin-top: 5px;
  border-top: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  margin-bottom: 30px;
}

.checkout_buttons .btn_primary {
  border: none;
}

.checkout_buttons .btn_secondary.back {
  color: #132322;
  border: 1px solid #132322;
}

.checkout_buttons .btn_secondary.back .back-image {
  left: 12px;
}

.checkout_buttons .btn {
  width: 220px;
}

.checkout_buttons .btn {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  .checkout_info {
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 40px 40px 0 40px;
    margin: 70px auto 100px auto;
  }
}

@media only screen and (max-width: 640px) {
  .checkout_buttons {
    display: block;
    justify-content: inherit;
  }

  .checkout_buttons .btn {
    margin-bottom: 15px;
    width: 100%;
  }
}
