.cart_contents_container {
  background-color: #fff;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px 30px;
}

.cart_quantity_label {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: "DM Mono", sans-serif;
  padding: 20px 0;
  background-color: #fff;
  color: #484c55;
  width: 80px;
}

.cart_desc_label {
  font-size: 16px;
  font-weight: 500;
  color: #484c55;
  display: inline-block;
}

.cart_contents_container .btn_secondary.back {
  color: #132322;
  border: 1px solid #132322;
}

.cart_contents_container .btn_secondary.back .back-image {
  left: 12px;
}

.cart_footer {
  padding: 40px 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .cart_quantity_label {
    width: 50px;
  }
  .cart_footer {
    display: block;
    justify-content: inherit;
    padding: 20px 0;
  }

  .cart_footer .btn {
    margin-bottom: 15px;
  }
}
