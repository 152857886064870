body,
html {
  font-family: DM Sans, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  /* letter-spacing: 1px; */
  background-color: #fff;
  color: #132322;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

:focus {
  outline: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  color: #484c55;
}

.page_wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

/*Sub header */
.subheader {
  position: relative; /* Need this here so the position: absolute on subelements anchors here rather than to the browser viewport */
  background: #474c55;
  height: 70px;
  color: #fff;
  font-size: 28px;
  padding-left: 15px;
  line-height: 66px;
}

.subheader_label {
  position: absolute;
  left: 110px;
  top: 25px;
  font-size: 32px;
  font-weight: 100;
  color: #fff;
}

h4 {
  font-size: 16px;
  line-height: 20px;
  /* letter-spacing: 2px; */
}
